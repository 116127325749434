<template>
  <div>
    <v-container class="">
      <div class="d-flex justify-end mb-3">
        <v-btn @click="createRequestDialog = true" color="blue" depressed dark>
          <span class="text-capitalize">Request payment</span>
        </v-btn>
      </div>

      <v-dialog
        v-model="createRequestDialog"
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title
            class="py-3 pr-4"
            :class="
              selectedPaymentType === 'One off payment'
                ? 'dialogColor'
                : 'secondary'
            "
          >
            <span class="white--text">Request a payment</span>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog()" text fab small color="white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-5">
            <v-select
              @change="newPaymentTypeSelected()"
              :items="paymentTypes"
              v-model="selectedPaymentType"
              label="Payment type"
              outlined
            ></v-select>

            <v-form v-if="selectedPaymentType === 'One off payment'">
              <transition name="slide-up-fade" mode="out-in" appear>
                <div>
                  <p>Request details</p>
                  <v-select
                    :items="entryTypes"
                    v-model="selectedEntryType"
                    label="Entry type"
                    outlined
                  ></v-select>
                </div>
              </transition>
              <transition-group name="slide-up-fade" tag="div">
                <div key="0" v-if="selectedEntryType">
                  <v-select
                    :items="bankAccounts"
                    v-model="selectedBankAccount"
                    label="Account"
                    outlined
                    item-text="AccountName"
                    item-value="OID"
                  ></v-select>
                </div>
                <div key="1" v-if="selectedBankAccount">
                  <v-text-field
                    :error-messages="amountIsInteger"
                    @input="setAmount"
                    label="Amount"
                    prefix="£"
                    outlined
                  ></v-text-field>
                </div>
                <div key="2" v-if="amount">
                  <v-text-field
                    @input="setDescription"
                    label="Description"
                    outlined
                    append-icon=""
                  ></v-text-field>
                </div>
                <div key="3" v-if="description">
                  <v-btn
                    @click="createOneOffRequest"
                    :loading="loading"
                    color="success"
                    block
                    depressed
                    large
                  >
                    <span class="custom-transform-class text-none"
                      >Create request</span
                    >
                  </v-btn>
                </div>
              </transition-group>
            </v-form>

            <v-form v-if="selectedPaymentType === 'Standing Order'">
              <transition name="slide-up-fade" mode="out-in" appear>
                <div>
                  <p>Request details</p>
                  <v-select
                    :items="entryTypes"
                    v-model="selectedEntryType"
                    label="Entry type"
                    outlined
                  ></v-select>
                </div>
              </transition>
              <transition-group name="slide-up-fade" tag="div">
                <div key="0" v-if="selectedEntryType">
                  <v-select
                    :items="bankAccounts"
                    v-model="selectedBankAccount"
                    label="Account"
                    outlined
                    item-text="AccountName"
                    item-value="OID"
                  ></v-select>
                </div>
                <div key="1" v-if="selectedBankAccount">
                  <v-select
                    :items="frequencies"
                    v-model="selectedFrequency"
                    label="Occurence"
                    outlined
                  ></v-select>
                </div>
                <div key="2" v-if="selectedFrequency">
                  <v-text-field
                    @input="setAmount"
                    label="Amount"
                    :error-messages="amountIsInteger"
                    prefix="£"
                    outlined
                  ></v-text-field>
                </div>
                <div key="3" v-if="amount">
                  <v-text-field
                    @input="setDescription"
                    label="Description"
                    outlined
                  ></v-text-field>
                </div>
                <div key="4" v-if="description">
                  <v-dialog
                    ref="dialog1"
                    v-model="startDateModal"
                    :return-value.sync="startDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class=""
                        :value="formattedStartDate"
                        label="First payment date"
                        :error-messages="startDateErrorMessage"
                        append-icon="mdi-calendar-cursor"
                        :aria-label="`First payment date ${startDate}`"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @keydown.enter="startDateModal = true"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="startDateModal = false"
                        @keyup.enter.stop="startDateModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog1.save(startDate)"
                        @keyup.enter.stop="$refs.dialog1.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div key="5" v-if="startDate">
                  <v-dialog
                    ref="dialog2"
                    v-model="endDateModal"
                    :return-value.sync="endDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class=""
                        :value="formattedEndDate"
                        label="Final payment date (optional)"
                        :error-messages="endDateErrorMessage"
                        append-icon="mdi-calendar-cursor"
                        :aria-label="`Last payment date ${endDate}`"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @keydown.enter="endDateModal = true"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="endDateModal = false"
                        @keyup.enter.stop="endDateModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(endDate)"
                        @keyup.enter.stop="$refs.dialog2.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div key="6" v-if="startDate">
                  <v-btn
                    @click="createSTO"
                    color="success"
                    large
                    block
                    depressed
                    >Create request</v-btn
                  >
                </div>
              </transition-group>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import moment from "moment/src/moment";

export default {
  name: "createPaymentRequestButton",
  created() {
    this.getBankAccounts();
  },
  data() {
    return {
      loading: false,
      createRequestDialog: false,
      bankAccounts: "",
      selectedBankAccount: "",
      paymentTypes: ["One off payment", "Standing Order"],
      selectedPaymentType: "",
      entryTypes: ["Other", "Deposit", "Rent"],
      selectedEntryType: "",
      amount: "",
      description: "",
      isTypingNumber: false,
      isTypingDescription: false,
      // Standing order ONLY values
      paymentSpecifics: ["Payment", "Lettings Deposit", "Rent"],
      selectedPaymentSpecific: "",
      frequencies: ["MONTHLY", "WEEKLY"],
      selectedFrequency: "",
      startDateModal: false,
      startDate: "",
      endDateModal: false,
      endDate: "",
    };
  },
  methods: {
    getBankAccounts() {
      this.$store.dispatch("PaymentRequests/getBankAccounts").then((res) => {
        this.bankAccounts = res.data;
      });
    },
    createOneOffRequest() {
      this.loading = true;
      const payload = {
        accountID: this.selectedBankAccount,
        personID: this.$route.params.id,
        description: this.description,
        amount: this.amount,
        entryType: this.selectedEntryType,
      };
      console.log("OneOff payload: ", payload);
      this.$store
        .dispatch("PaymentRequests/createOneOffPayment", {
          accountID: this.selectedBankAccount,
          personID: this.$route.params.id,
          description: this.description,
          amount: this.amount,
          entryType: this.selectedEntryType,
        })
        .then((res) => {
          console.log("Created oneOffPayment response: ", res);
          this.loading = false;
          this.$emit("newRequestCreated");
          this.closeDialog();
        })
        .catch((err) => {
          console.log("error: ", err);
          this.$emit("errorCreatingRequest");
          this.loading = false;
        });
    },
    createSTO() {
      this.loading = true;
      const payload = {
        personID: this.$route.params.id,
        accountID: this.selectedBankAccount,
        firstPayment: moment(this.startDate).toISOString(),
        lastPayment: this.endDate,
        frequency: this.selectedFrequency,
        description: this.description,
        amount: this.amount,
        entryType: this.selectedEntryType,
      };
      console.log("STO payload: ", payload);
      this.$store
        .dispatch("PaymentRequests/createStoPayment", payload)
        .then((res) => {
          console.log("STO creation res: ", res);
          this.loading = false;
          this.$emit("newRequestCreated");
          this.closeDialog();
        })
        .catch((err) => {
          console.log("error: ", err);
          this.$emit("errorCreatingRequest");
          this.loading = false;
        });
    },
    newPaymentTypeSelected() {
      this.clearValues();
    },
    closeDialog() {
      this.clearValues();
      this.clearPaymentType();
      this.createRequestDialog = false;
    },
    clearValues() {
      this.selectedAccount = "";
      this.selectedBankAccount = "";
      this.amount = "";
      this.description = "";
      this.selectedPaymentSpecific = "";
      this.selectedFrequency = "";
      this.selectedEntryType = "";
      this.startDate = "";
      this.endDate = "";
    },
    clearPaymentType() {
      this.selectedPaymentType = "";
    },
    rowColor(item) {
      if (item.state === "Fully paid") return "green lighten-5";
      if (item.state === "Cancelled") return "red lighten-5";
      return "";
    },
    setAmount: debounce(function (val) {
      const parsedString = parseInt(val);
      this.amount = parsedString;
    }, 500),
    setDescription: debounce(function (val) {
      this.description = val;
    }, 1000),
  },
  computed: {
    amountIsInteger() {
      if (this.amount) {
        if (typeof this.amount === "number") return "";
        return "The amount has to be a valid integer.";
      }
      return "";
    },
    controlStartDate() {
      const threeDaysInFuture = moment().add(2, "days").format();
      return threeDaysInFuture.substr(0, 10);
    },
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format("DD/MM/YYYY") : "";
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    },
    startDateErrorMessage() {
      if (this.startDate <= this.controlStartDate)
        return "Date must be at least 3 days from today.";
      return "";
    },
    // startDateError() {
    //   if(this.startDate <= this.controlStartDate) return true
    //   return false
    // },
    startEndDateDifference() {
      if (this.endDate) {
        const startDate = moment(this.startDate);
        const endDate = moment(this.endDate);
        const daysApart = endDate.diff(startDate, "days");
        return daysApart;
      }
      return 100000000;
    },
    endDateErrorMessage() {
      if (this.selectedFrequency === "MONTHLY") {
        if (this.startEndDateDifference < 30)
          return "Start and end dates must be at least a month apart";
      }
      if (this.selectedFrequency === "WEEKLY") {
        if (this.startEndDateDifference < 7)
          return "Start and end dates must be at least a week apart";
      }
      return "";
    },
    // endDateError() {
    //   if(this.paymentFrequency === 'MONTHLY') {
    //     if(this.startEndDateDifference < 30) return true
    //   }
    //   return false
    // }
  },
};
</script>

<style>
</style>
