<template>
  <div>
    <v-container class="">
      <CreateRequestButton 
        @newRequestCreated="refreshRequestData('Payment request created.')"
      />

      <IndividualRequestsTable 
        ref="requestsTable" 
        @requestCancelled="refreshRequestData('Payment request cancelled.')"
        @requestCancelledFailed="showSnackbarError('Error whilst cancelling request. Try again.')"
      />

      <Snackbar 
        :snackbarDialog="snackbar"
        :snackbarText="snackbarText"
        :snackbarColor="snackbarColor"
        @closeSnackbar="closeSnackbar()"
        @errorCreatingRequest="showSnackbarError()"
      />
    </v-container>
  </div>
</template>

<script>
import CreateRequestButton from '@/components/PaymentRequests/CreateRequestButton.vue';
import IndividualRequestsTable from '@/components/PaymentRequests/IndividualRequestsTable.vue';
import Snackbar from '@/components/Snackbars/TheSnackbar'

export default {
  name: 'Payments',
  components: {
    CreateRequestButton,
    IndividualRequestsTable,
    Snackbar
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    }
  },
  methods: {
    refreshRequestData(msg) {
      this.snackbar = true
      this.snackbarText = msg
      this.snackbarColor = 'green'
      this.$refs.requestsTable.getPaymentRequestDetails() // calling method in table component to refresh data
    },
    showSnackbarError() {
      this.snackbar = true
      this.snackbarText = 'Error wilst creating request. Please try again.'
      this.snackbarColor = 'red'
    },
    closeSnackbar() {
      this.snackbar = false
      this.snackbarText = ''
      this.snackbarColor = ''
    },
  }
}
</script>

<style>

</style>
