<template>
  <div>
    <v-card flat class="">
      <v-card-title v-if="userDetails" class="dialogColor d-flex align-start ">
        <div class="white--text d-flex align-center">
          <div class="ml-1">
            <p class="my-0 text-h5">{{ userDetails.Name }}</p>
            <p class="my-0 text-caption font-weight-light">{{ userDetails.PersonType}}</p>
            <!-- <p class="my-0 text-caption font-weight-light">{{ userDetails.OID }}</p> -->
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon color="white" size="50">{{ iconType }}</v-icon>
      </v-card-title>
      <v-card-text v-if="paymentRequestDetails">
        <v-data-table
          :headers="headers"
          :items="paymentRequestDetails"
          class="elevation-0"
          :item-class="rowColor"
          no-data-text="No payment requests present"
          sort-by="Status"
          sort-desc
        >

          <template v-slot:item.PaymentType="{ item }">
            <span class="d-flex align-center">
              <v-icon v-if="item.PaymentType === 'Payment'" left>mdi-credit-card-outline</v-icon>
              <v-icon v-else left>mdi-autorenew</v-icon>
              {{ item.PaymentType }}</span>
          </template>

          <template v-slot:item.Amount="{ item }">
            <span class="">{{ item.Amount | formatAmount }}</span>
          </template>

          <template v-slot:item.Status="{ item }">
            <span v-if="item.Status === 'Requested'">{{ item.Status }} <span class="ml-3 font-weight-light">{{ item.RequestedDateTime | formatDateTime }}</span></span>
            <span v-else>{{ item.Status }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  dark
                  small
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  class="text-capitalize"
                >
                  Actions
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list class="secondary py-0" dark>
                <v-list-item @click="viewRequestDeatils(item)">
                  <v-list-item-title>View request details</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="openPayAgentApp(item)">
                  <v-list-item-title>Open request in app</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-cellphone-link</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="copyAppLinkToClipboard(item)">
                  <v-list-item-title>Copy request link</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-content-copy</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="openNativeEmail(item)">
                  <v-list-item-title>Send request by email</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-email-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="openCancelConfirmDialog(item)">
                  <v-list-item-title>Cancel Request</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="requestDetailsDialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title class="dialogColor white--text py-0">
          Request details
          <v-spacer></v-spacer>
          <v-btn @click="closeRequestDetailsDialog" color="white" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1">
          <RequestDetailsTable
            :item="selectedItem"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="cancelConfirmDialog"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title class="dialogColor white--text py-0">
          Cancel request
          <v-spacer></v-spacer>
          <v-btn @click="closeCancelConfirmDialog" color="white" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <p class="text-h6 accent--text">Do you want to cancel this payment request?</p>
          <RequestDetailsTable
            :item="selectedItem"
          />
          <div class="d-flex mt-8">
            <v-btn 
              @click="closeCancelConfirmDialog"
              color="red"
              outlined
              dark
            >Close</v-btn>
            <v-spacer></v-spacer>
            <v-btn 
              @click="cancelRequest"
              :loading="loading"
              color="success"
              depressed
            >Cancel request</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import moment from 'moment/src/moment'
import RequestDetailsTable from '@/components/PaymentRequests/RequestDetailsTable.vue';

export default {
  name: 'individualPaymentRequestsTable',
  components: {
    RequestDetailsTable
  },
  created() {
    this.getUserDetails()
    this.getPaymentRequestDetails()
  },
  data() {
    return {
      loading: false,
      userDetails: '',
      paymentRequestDetails: '',
      selectedItem: '',
      requestDetailsDialog: false,
      cancelConfirmDialog: false,
      headers: [
        { text: 'Type', value: 'PaymentType'},
        { text: 'Amount', value: 'Amount'},
        // { text: 'Descrition', value: 'Breakdown'},
        { text: 'Staus', value: 'Status'},
        { text: '', value: 'appLinks', align: 'end'},
        { text: '', value: 'actions', align: 'end'},
        // { text: 'Requested', value: 'requested'},
        // { text: 'Paid', value: 'paid'},
        // { text: 'Outstanding', value: 'outstanding'},
      ],
    }
  },
  methods: {
    openCancelConfirmDialog(item) {
      this.selectedItem = item
      this.cancelConfirmDialog = true
    },
    closeCancelConfirmDialog() {
      this.cancelConfirmDialog = false
      this.selectedItem = ''
    },
    cancelRequest() {
      this.loading = true
      this.$store.dispatch('PaymentRequests/cancelPaymentRequest', this.selectedItem.PaymentRedirectReference)
        .then( () => {
          this.loading = false
          this.closeCancelConfirmDialog() 
          this.$emit('requestCancelled')
        })
        .catch( err => {
          this.loading = false
          this.closeCancelConfirmDialog() 
          this.$emit('requestCancelledFailed')
          console.log('Error cancelling request: ', err);
        })
    },
    openPayAgentApp(item) {
      const idRef = item.PaymentRedirectReference
      const url = `https://www.payagent.app?requestid=${idRef}`
      window.open(url, '_blank')
    },
    copyAppLinkToClipboard(item) {
      const idRef = item.PaymentRedirectReference
      const str = `https://www.payagent.app?requestid=${idRef}`
      // Create new element
      var el = document.createElement('textarea');
      // Set value (string to be copied)
      el.value = str;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute('readonly', '');
      el.style = {position: 'absolute', left: '-9999px'};
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand('copy');
      // Remove temporary element
      document.body.removeChild(el);
    },
    openNativeEmail(item) {
      const idRef = item.PaymentRedirectReference
      const url = `https://www.payagent.app?requestid=${idRef}`
      window.location.href = `mailto:${this.userDetails.Email}?subject=${item.Breakdown[0]}&body=Hi ${this.userDetails.Name},%0a%0aWe are contacting you regarding the following payment request: ${item.Breakdown[0]}. %0a%0aYou can take care of payments by clicking on this link: %0a%0a${url} %0a%0aPayAgent by AgentOS is quick and secure, and your security details will not be shared with us %0a%0a%0a%0aKind regards, %0a${this.$store.state.user.UserDisplayName} %0a%0a`
    },
    viewRequestDeatils(item) {
      this.selectedItem = item
      this.requestDetailsDialog = true
    },
    closeRequestDetailsDialog() {
      this.selectedItem = ''
      this.requestDetailsDialog = false
    },
    getUserDetails() {
      const personID = this.$route.params.id
      this.$store.dispatch('getSpecificPersonById', personID)
        .then( res => {
          this.userDetails = res.data
        })
    },
    getPaymentRequestDetails() {
      const todaysDate = moment().toISOString()
      const personID = this.$route.params.id

      this.$store.dispatch('PaymentRequests/getPaymentRequests', {
        dateTo: todaysDate,
        personID: personID
      })
        .then(res => {
          // console.log('person requests: ', res)
          this.paymentRequestDetails = res.data
        })
    },
    rowColor(item) {
      if(item.Status === 'FullyPaid') return 'green lighten-5'
      if(item.Status === 'Cancelled') return 'red lighten-5'
      return ''
    }
  },
  computed: {
    iconType() {
      if(this.userDetails.PersonType === 'Tenant') return 'mdi-home-account'
      if(this.userDetails.PersonType === 'Landlord') return 'mdi-account-tie'
      if(this.userDetails.PersonType === 'Contractor') return 'mdi-tools'
      return 'mdi-account'
    }
  }
}
</script>

