<template>
  <div>
    <v-simple-table v-if="item">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="font-weight-medium">Payment type</td>
            <td>{{ item.PaymentType }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Requested</td>
            <td>{{ item.RequestedDateTime | formatDateTime }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Amount</td>
            <td>{{ item.Amount | formatAmount }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Status</td>
            <td>{{ item.Status }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Breakdown</td>
            <td>{{ item.Breakdown[0] }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Outstanding amount</td>
            <td>{{ item.OutstandingAmount | formatAmount }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'requestsDetailsTable',
  props: {
    item: {
      type: [Object, String ],
      required: false
    }
  }
}
</script>

<style>

</style>